import { Icon, IconName } from "@/types/icons";
import {
  IconBolt,
  IconCake,
  IconCalendar,
  IconChevronDown,
  IconChevronUp,
  IconDots,
  IconEdit,
  IconLink,
  IconMapPin,
  IconX,
  IconCheck,
  IconSquareRotated,
  IconSend,
  IconArrowRight,
  IconArrowUp,
  IconLoader2,
  IconTrash,
  IconCameraPlus,
  IconUserScan,
  IconLayoutDashboard,
  IconCopy,
  IconWallet,
  IconLogout,
  IconPlus,
  IconCreditCardPay,
  IconSearch,
  IconMaximize,
  IconCurrentLocation,
  IconZoomIn,
  IconZoomOut,
  IconBuildingStore,
  IconPhone,
  IconMail
} from "@tabler/icons-react";

export const icons: { [key in IconName]: Icon } = {
  "chevron-down": IconChevronDown,
  "chevron-up": IconChevronUp,
  "map-pin": IconMapPin,
  bolt: IconBolt,
  cake: IconCake,
  calendar: IconCalendar,
  dots: IconDots,
  edit: IconEdit,
  link: IconLink,
  user: IconMapPin,
  x: IconX,
  check: IconCheck,
  placeholder: IconSquareRotated,
  send: IconSend,
  "arrow-right": IconArrowRight,
  "arrow-up": IconArrowUp,
  "loader": IconLoader2,
  thrash: IconTrash,
  "camera-plus": IconCameraPlus,
  "user-scan": IconUserScan,
  "dashboard": IconLayoutDashboard,
  copy: IconCopy,
  wallet: IconWallet,
  logout: IconLogout,
  plus: IconPlus,
  "credit-card-pay": IconCreditCardPay,
  search: IconSearch,
  maximize: IconMaximize,
  "current-location": IconCurrentLocation,
  "zoom-in": IconZoomIn,
  "zoom-out": IconZoomOut,
  "building-store": IconBuildingStore,
  "phone": IconPhone,
  "mail": IconMail
};
